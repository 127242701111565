<template>
    <div class="mt-3 px-xl-5">
        <b-row class="justify-content-end">
            <b-col xl="3" md="6">
                <b-input-group class="mt-3">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm"></b-form-input>
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="my-3">
            <b-col class="text-end">
                <b-button class="main-btn">
                    <b-icon-clipboard-check scale="1"></b-icon-clipboard-check>
                </b-button>
                <b-button class="main-btn ms-2">
                    <b-icon-envelope-open scale="1.2"></b-icon-envelope-open>
                </b-button>
                <b-button class="main-btn ms-2" @click="$bvModal.show('sanction')">
                    <b-icon-exclamation-square scale="1.2"></b-icon-exclamation-square>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mt-2 mb-4">
            <h6 class="page-title pb-2" v-if="user == 'resident-agent'">REQUERIMIENTOS AGENTE RESIDENTE</h6>
            <h6 class="page-title pb-2" v-if="user == 'authorities'">REQUERIMIENTOS AUTORIDAD COMPETENTE</h6>
            <b-col xl="5" md="8" class="d-flex">
                <b-card no-header no-body
                        class="shadow-sm card-tab px-3"
                        v-bind:class="{'active': selectedCard == 'pending'}"
                        id="pending"
                        @click="setActive($event)">
                    <label class="card-tab-title">
                        Requerimientos pendientes
                        <b-icon-exclamation-square class="ms-1"></b-icon-exclamation-square>
                    </label>
                </b-card>
                <b-card no-header no-body
                        class="shadow-sm card-tab ms-3 px-3"
                        v-bind:class="{'active': selectedCard == 'managed'}"
                        id="managed"
                        @click="setActive($event)">
                    <label class="card-tab-title">
                        Requerimientos gestionados
                        <b-icon-check-square class="ms-1"></b-icon-check-square>
                    </label>
                </b-card>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-card no-header no-body
                        class="shadow-sm card-tab px-3 icon-wrap"
                        v-bind:class="{'active': user == 'resident-agent'}"
                        id="resident-agent"
                        @click="setUser($event)">
                    <label class="card-tab-title">
                        <b-icon-person scale="1.5"></b-icon-person>
                    </label>
                </b-card>
                <b-card no-header no-body
                        class="shadow-sm card-tab px-3 ms-2 icon-wrap"
                        v-bind:class="{'active': user == 'authorities'}"
                        id="authorities"
                        @click="setUser($event)">
                    <label class="card-tab-title">
                        <b-icon-building scale="1.5"></b-icon-building>
                    </label>
                </b-card>
            </b-col>
        </b-row>
        <ResidentAgentPendingRequests v-if="selectedCard =='pending' && user == 'resident-agent'" />
        <ResidentAgentManagedRequests v-if="selectedCard =='managed' && user == 'resident-agent'" />
        <CompetentAuthoritiesManagedRequests v-if="selectedCard =='managed' && user == 'authorities'"/>
        <CompetentAuthoritiesPendingRequests v-if="selectedCard =='pending' && user == 'authorities'"/>
        <NewSanctionModal/>
    </div>
</template>

<script>
    import ResidentAgentManagedRequests from "./ResidentAgentManagedRequests.vue";
    import ResidentAgentPendingRequests from "./ResidentAgentPendingRequests.vue";
    import CompetentAuthoritiesManagedRequests from "./CompetentAuthoritiesManagedRequests.vue";
    import CompetentAuthoritiesPendingRequests from "./CompetentAuthoritiesPendingRequests.vue";
    import NewSanctionModal from "./NewSanctionModal.vue";

    export default {
        name: "superintendencia-requests",
        components: {
            ResidentAgentManagedRequests,
            ResidentAgentPendingRequests,
            CompetentAuthoritiesManagedRequests,
            CompetentAuthoritiesPendingRequests,
            NewSanctionModal
        },
        data() {
            return {
                selectedCard: 'pending',
                user: 'resident-agent'
            };
        },
        methods: {
            setActive(e) {
                this.selectedCard = e.currentTarget.id;
            },
            setUser(e) {
                this.user = e.currentTarget.id;
            }
        }
    };
</script>

<style scoped>
    .card-tab {
        padding: 0.4rem 0;
        border-radius: 0;
    }

    .card-tab-title {
        font-weight: 500;
        color: #717171;
        text-align: center;
    }

    .card-tab.active {
        background: rgb(232 243 255);
        border: 2px solid #406892;
    }

        .card-tab.active .card-tab-title {
            color: #406892;
        }

    @media (max-width: 765px) {
        .icon-wrap {
            margin-top: 1rem;
        }
    }
</style>