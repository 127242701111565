<template>
    <div>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
            <b-table
                id="pending-table"
                :current-page="currentPage"
                :per-page="perPage"
                small
                stacked="md"
                :items="items"
                :fields="fields"
                show-empty
                empty-text="No hay requerimientos pendientes que mostrar"
            >
                <template v-slot:head(trafficLight)>
                    <b-icon-stoplights scale="1.5"></b-icon-stoplights>
                </template>
                <template v-slot:cell(requestType)>
                    <b-icon-envelope scale="1.2"></b-icon-envelope>
                </template>
                <template v-slot:cell(trafficLight)>
                    <b-icon-square-fill
                        class="green-traffic-light"
                    ></b-icon-square-fill>
                </template>
            </b-table>
        </b-card>
        <b-row class="mb-5 mt-4">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    size="sm"
                    class="my-0 justify-content-end"
                >
                    <template #first-text>
                        <span
                            ><b-icon-chevron-double-left
                                scale="1.3"
                            ></b-icon-chevron-double-left
                        ></span>
                    </template>
                    <template #prev-text>
                        <span
                            ><b-icon-chevron-left
                                scale="1.3"
                            ></b-icon-chevron-left
                        ></span>
                    </template>
                    <template #next-text>
                        <span
                            ><b-icon-chevron-right
                                scale="1.3"
                            ></b-icon-chevron-right
                        ></span>
                    </template>
                    <template #last-text>
                        <span
                            ><b-icon-chevron-double-right
                                scale="1.3"
                            ></b-icon-chevron-double-right
                        ></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "resident-agent-pending-requests",
    data() {
        return {
            fields: [
                { key: "requestDate", label: "Fecha petici\u00f3n" },
                { key: "residentAgent", label: "Agente residente" },
                { key: "topic", label: "Asunto" },
                { key: "legalEntity", label: "Personas jur\u00eddicas" },
                { key: "ruc", label: "RUC" },
                { key: "requestType", label: "Tipo petici\u00f3n" },
                { key: "trafficLight", label: "" },
            ],
            //demo purpose only
            items: [
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
                {
                    requestDate: "Fecha peticion",
                    residentAgent: "Agente residente",
                    topic: "Asunto peticion",
                    legalEntity: "Nombre entidad juridica",
                    ruc: "Numero RUC",
                    requestType: "",
                    trafficLight: "",
                },
            ],
            rows: 1,
            currentPage: 1,
            perPage: 10,
        };
    },
    mounted() {
        this.rows = this.items.length;
    },
};
</script>

<style scoped>
.green-traffic-light {
    color: #2aad79;
}

.red-traffic-light {
    color: red;
}

@media (max-width: 765px) {
    ::v-deep #pending-table > tbody > tr > td[aria-colindex="7"] {
        padding-left: 6rem;
    }
}

@media (min-width: 768px) {
    ::v-deep #pending-table > tbody > tr > td[aria-colindex="6"] {
        text-align: center;
    }
}
</style>