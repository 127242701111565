<template>
    <div>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
            <b-table id="managed-table"
                     :current-page="currentPage"
                     :per-page="perPage"
                     small
                     stacked="md"
                     :items="items"
                     :fields="fields"
                     show-empty
                     empty-text="No hay requerimientos gestionados que mostrar">
                <template v-slot:cell(requestType)>
                    <b-icon-envelope scale="1.2"></b-icon-envelope>
                </template>
                <template v-slot:cell(comment)>
                    <b-icon-chat-square scale="1.2"></b-icon-chat-square>
                </template>
            </b-table>
        </b-card>
        <b-row class="mb-5 mt-4">
            <b-col>
                <b-pagination v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                              size="sm"
                              class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "competent-authorities-managed-requests",
        data() {
            return {
                fields: [
                    { key: 'endDate', label: "Fecha finalizaci\u00f3n" },                   
                    { key: 'competentAuthorities', label: "Autoridad competente" },
                    { key: 'topic', label: "Asunto" },
                    { key: 'legalEntity', label: "Personas jur\u00eddicas" },
                    { key: 'ruc', label: "RUC" },                
                    { key: 'requestDate', label: "Fecha petici\u00f3n" },
                    { key: 'requestType', label: "Tipo petici\u00f3n" },
                    { key: 'comment', label: "Comentarios" },
                ],
                items: [
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: ''},
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' },
                    { endDate: 'Fecha finalizacion', competentAuthorities: 'Autoridad competente', topic: 'Asunto peticion', legalEntity: 'Nombre entidad juridica', ruc: 'RUC', requestDate: 'Fecha peticion', requestType: '', comment: '' }
                ],
                rows: 1,
                currentPage: 1,
                perPage: 10
            };
        },
        mounted() {
            this.rows = this.items.length
        }
    };
</script>

<style scoped>
    @media (min-width: 768px) {
        ::v-deep #managed-table > tbody > tr > td[aria-colindex="7"],
        ::v-deep #managed-table > tbody > tr > td[aria-colindex="8"] {
            text-align: center;
        }
    }
</style>