<template>
    <div>
        <b-modal id="sanction"
                 content-class="rounded-0"
                 body-class="px-md-5"
                 dialog-class="long-modal"
                 header-class="border-0"
                 header-bg-variant="light"
                 hide-header-close
                 centered no-close-on-backdrop hide-footer>
            <h6 class="card-title-color mt-2">
                <b-icon-exclamation-square class="me-1"></b-icon-exclamation-square> SANCI&Oacute;N
            </h6>
            <b-card border-variant="light" bg-variant="light">
                <b-card-text>
                    <form ref="form" @submit.stop.prevent="handlePersonForm">
                        <b-row class="mt-2 px-4">
                            <b-col>
                                <b-form-group>
                                    <label class="sanction-title" label-for="name-input">Persona jurídica</label>
                                    <b-form-input id="name-input"
                                                  v-model="legalEntity"
                                                  size="sm"
                                                  required
                                                  class="rounded-0"></b-form-input>
                                </b-form-group>
                                <b-form-group class="mt-3">
                                    <label class="sanction-title" label-for="name-input">Asunto</label>
                                    <b-form-input id="name-input"
                                                  v-model="topic"
                                                  size="sm"
                                                  required
                                                  class="rounded-0"></b-form-input>
                                </b-form-group>
                                <b-form-group class="mt-3">
                                    <label class="sanction-title" label-for="name-input">Petici&oacute;n</label>
                                    <b-form-textarea v-model="request"
                                                     size="lg"
                                                     class="rounded-0"></b-form-textarea>
                                </b-form-group>
                                <b-row class="mt-4">
                                    <b-col md="6">                                      
                                        <div class="file-input mt-1">
                                            <label for="file" class="btn main-btn btn-secondary"><b-icon-file-earmark-plus class="me-1"></b-icon-file-earmark-plus>Seleccionar archivo</label>
                                            <input id="file" type="file" @change="onInputChange">
                                        </div>
                                    </b-col>
                                    <b-col class="file-name-container mt-2">
                                        <span v-if="fileName" class="file-name">{{fileName}}</span>
                                        <span v-else class="file-name">Ning&uacute;n archivo seleccionado</span>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-5 mb-2">
                                    <b-col class="text-end">
                                        <b-button class="main-btn" @click="$bvModal.hide('sanction')">Cancelar</b-button>
                                        <b-button class="ms-2 main-btn">Guardar</b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </form>
                </b-card-text>
            </b-card>
        </b-modal>      
    </div>   
</template>

<script>
    export default {
        name: 'new-sanction',
        data() {
            return {
                legalEntity: null,
                topic: null,
                request: null,
                file: null,
                fileName: null
            };
        },
        methods: {
            onInputChange(e) {
                let file = e.target.files[0];
                this.fileName = file.name
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    this.file = e.target.result;
                    console.log(this.file)
                };
            }
        }
    }
</script>

<style scoped>
    ::v-deep #sanction .modal-header {
        background-color: rgb(232 243 255) !important;
    }

    .sanction-title {
        font-size: 14px;
        font-weight: 500;
        color: #717171;       
    }
</style>